import React, { useEffect, useState } from 'react';
// import { Builder } from '@builder.io/react';
import { convertTZ } from '../../utils/datetime';

const TimeLimitContainer = props => {
  const {
    startMonth,
    startDay,
    startYear,
    startHour,
    startMinutes,
    endMonth,
    endDay,
    endYear,
    endHour,
    endMinutes,
    children,
    timezone,
    hideOnExpiry
  } = props;

  const [timeExpired, setTimeExpired] = useState(true);
  const [checkExpired, setCheckExpired] = useState(true);

  const defaultTZ = timezone || 'Europe/London';

  const startDate = convertTZ(
    `${startMonth}/${startDay}/${startYear} ${startHour}:${startMinutes}:00 ${defaultTZ}`,
    defaultTZ
  );
  const endDate = convertTZ(
    `${endMonth}/${endDay}/${endYear} ${endHour}:${endMinutes}:00 ${defaultTZ}`,
    defaultTZ
  );

  const startTime = new Date(startDate).getTime();
  const endTime = new Date(endDate).getTime();

  useEffect(() => {
    if (checkExpired) {
      const timer = setTimeout(() => {
        const currentDate = convertTZ(new Date(), defaultTZ);
        const currentTime = new Date(currentDate).getTime();
        const isExpired = currentTime < startTime || currentTime > endTime;

        // console.log("currentDate", currentDate)
        // console.log("currentTime", currentTime)
        // console.log("startTime", startDate, startTime)
        // console.log("endTime", endDate, endTime)
        // console.log("start", (currentTime < startTime))
        // console.log("end", (currentTime > endTime))
        // console.log("isExpired", isExpired)
        // console.log("timeExpired", timeExpired)

        setTimeExpired(isExpired);
        setCheckExpired(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [
    checkExpired,
    setCheckExpired,
    defaultTZ,
    startDate,
    startTime,
    endDate,
    endTime,
    timeExpired
  ]);

  // const onBuilder = (Builder.isPreviewing || Builder.isEditing);

  if (
    timeExpired &&
    hideOnExpiry
    // && !onBuilder
  ) {
    return <></>;
  } else {
    return <div>{children}</div>;
  }
};

export default TimeLimitContainer;
